<template>
    <div ref="optionsContainer" class="o365-select-options-definitions d-none">
        <slot></slot>
    </div>
    <ODropdown ref="dropdownRef" @beforeopen="onBeforeOpen" @open="onOpen" @beforeclose="onBeforeClose">
        <template #default="{target, open }">
            <slot name="target" :target="target" :open="open" :clear="clearValue">
                <div class="position-relative" :class="wrapperClass">
                    <input :ref="target" :value="displayValue" readonly class="o365-select text-truncate" @click="open" @blur="preventBlur"
                        :class="{'lookup-icon': !noIcon && !(clearable && modelValue)}"
                        @focus="open" v-bind="$attrs">
                    <button v-if="clearable && modelValue" class="btn btn-sm btn-link py-0 o365-select-clear me-2" @click="clearValue"
                        :title="$t('Clear')">
                        <i class="bi bi-x-lg"></i>
                    </button>
                </div>      
            </slot>
        </template>
        <template #dropdown="{container, close}">
            <div :ref="container" class="shadow dropdown-menu card o365-select-container" :class="containerClass" :style="{'width': dropdownWidth+'px'}">
                <button v-for="(option, index) in selectOptions"  @click="() => { selectValue(option.value, close);}" class="dropdown-item py-0"
                    :style="{'height': itemSize + 'px'}" 
                    :class="[option.class, {'active': option.active, 'focused': index === navigationControl.currentIndex && !multiple}, itemClass]">
                    <input v-if="multiple" type="checkbox" :checked="option.active"  class="form-check-input">
                    {{option.display ?? option.value}}
                </button>
            </div>
        </template>
    </ODropdown>
</template>

<script setup lang="ts">
// DO NOT ADD COMMENTS OR OTHER NODES ON TEMPALTE ROOT
// This is an input editor therefore it must have a single root noode
// v-if-else chains are ok as long as only one node is rendered

import ODropdown from './Dropdown.vue';
import { ref, computed, nextTick, onMounted, onUpdated, reactive, defineComponent } from 'vue';

// defineOptions({ inheritAttrs: false });
const props = withDefaults(defineProps<{
    modelValue: any,
    multiple?: boolean,
    itemSize?: number | string,
    minWidth?: number | string,
    widthPadding?: number | string,
    /** Add a clear icon into the input. */
    clearable?: boolean,
    wrapperClass?: any,
    containerClass?: any,
    itemClass?: any,
    noIcon?: boolean,
    openOnMount?:false
}>(), {
    itemSize: 24,
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void
    (e: 'selected', value: any): void
}>();

const internalMultiSelectValue = computed(()=>{
    
                
    try{
        if(props.modelValue?.constructor == Array){
            return props.modelValue;
        }
        return JSON.parse(props.modelValue).map(x=>x.trim());
       // return JSON.parse(props.modelValue).map(x=>x);
    }catch{
        return props.modelValue?.split(", ");
    }
})

const dropdownRef = ref<ODropdown|null>(null);
const optionsContainer = ref<HTMLElement|null>(null);
const dropdownWidth = ref(200);
const id = crypto.randomUUID();
const options = ref<{
    value: any,
    display: any,
    class: string,
}[]>([]);

const displayValue = computed(() => {
    if(props.multiple){
        return internalMultiSelectValue.value?internalMultiSelectValue.value.join(', '):null;
    }
    const selectedOption = options.value.find(x => x.value == props.modelValue);
    if (selectedOption) {
        return selectedOption.display;
    } else {
        return props.modelValue;
    }
});
const selectOptions = computed(() => {
    let activeFound = false;
    return options.value.map(option => {
        let isActive = false;
        if (props.multiple) {
            //const selectedArray = props.modelValue && Array.isArray(props.modelValue) ? props.modelValue : (props.modelValue?[props.modelValue]:[]);
            const selectedArray = internalMultiSelectValue.value && Array.isArray(internalMultiSelectValue.value) ? internalMultiSelectValue.value : (internalMultiSelectValue.value?[internalMultiSelectValue.value]:[]);
           
            isActive = selectedArray.includes(option.value);
        } else {
            isActive = !activeFound && option.value === props.modelValue;
            if (isActive) { activeFound = true; }
        }

        return {
            value: option.value,
            display: option.display,
            class: option.class,
            active: isActive
        };
    });
});

function preventBlur(pEvent) {
    try {
        if(dropdownRef.value?.container?.contains(pEvent.relatedTarget)) {
            pEvent.stopPropagation();
        }
    } catch (ex) {
        console.error(ex);
    }
    closeDropdown(pEvent);
}

const navigationControl = reactive(new SelectNavigationControl({
    open: () => {
        if (dropdownRef.value?.open) {
            dropdownRef.value.open();
        }
    },
    close: () => {
        if (dropdownRef.value?.close) {
            dropdownRef.value.close();
        }
    },
    isOpen: () => dropdownRef.value?.isOpen,
    isValidIndex: (pIndex) => !!selectOptions.value[pIndex],
    updateValue: (pIndex) => selectValue(selectOptions.value[pIndex].value),
    getScrollContainer: () => dropdownRef.value?.container,
    multiple: props.multiple
}));

function selectValue(pValue: any, pClose?: ()=> void) {
    if (props.multiple) {
        let array = internalMultiSelectValue.value;
       
        
        if (internalMultiSelectValue.value == null || !Array.isArray(internalMultiSelectValue.value)) {
            array = [];
        }
        const existingIndex = array.findIndex(x => x === pValue);
        if (existingIndex !== -1) { 
            array.splice(existingIndex, 1)
        } else {
            array.push(pValue);
        }
        emit('update:modelValue', array);
        emit('selected', array);
    } else {
        emit('update:modelValue', pValue);
        emit('selected', pValue);
        if (pClose) {
            pClose();
        }
    }
}

function onBeforeOpen() {
    dropdownWidth.value = dropdownRef.value.target.getClientRects()?.[0]?.width;
    if (props.minWidth && dropdownWidth.value < props.minWidth) {
        dropdownWidth.value = +props.minWidth;
    }
    if (props.widthPadding) {
        dropdownWidth.value += +props.widthPadding;
    }
    const index = selectOptions.value.findIndex(x => x.active);
    navigationControl.addHandler(dropdownRef.value.target, index === -1 ? undefined : index);
}

function onBeforeClose() {
    navigationControl.removeHandler();
}

function onOpen() {
    const activeIndex = selectOptions.value.findIndex(x => x.active);
    nextTick().then(() => {
        const contentWidth = dropdownRef.value.container.scrollWidth + 32;
        if (props.minWidth == null && dropdownWidth.value < contentWidth) {
            dropdownWidth.value = contentWidth;
        }
    });
    if (activeIndex) {
        nextTick().then(() => {
            dropdownRef.value.container.scrollTop = activeIndex * 32 - 400;
        });
    }
}

function clearValue() {
    emit('update:modelValue', null);
}

function getOptionsFromDOM() {
    options.value = [];
    if (optionsContainer.value) {
        optionsContainer.value.querySelectorAll('option').forEach(option => {
            options.value.push({
                value: (option as any)._value ?? option.value ?? (option.innerText || undefined),
                display: option.innerText,
                class: option.className
            });
        });
    }
}

function closeDropdown(event: FocusEvent) {
    if ((event.relatedTarget as HTMLElement)?.closest('.o365-select-container')) {
        return;
    }
    if (dropdownRef.value?.close) {
        dropdownRef.value.close();
    }    
}

onMounted(() => {
    getOptionsFromDOM();
    if(props.openOnMount)
        _open();
});
onUpdated(() => {
    getOptionsFromDOM();
        console.log("Updated");
})

function _open() {
    if (dropdownRef.value?.open) {
        dropdownRef.value.open();
    }    
}
function _close() {
    if (dropdownRef.value?.close) {
        dropdownRef.value.close();
    }    
}

defineExpose({open: _open, close: _close});

</script>

<script lang="ts">
class SelectNavigationControl {
    currentIndex: number|null = null;
    lastKeyIsNavigation: boolean;
    private _input: HTMLElement;
    private _boundKeyDown: typeof this._handleKeyDown;
    private _apiObject: {
        open: () => void;
        close: () => void;
        isOpen: () => boolean;
        isValidIndex: (pIndex: number) => boolean;
        updateValue: (pIndex: number) => void;
        getScrollContainer: () => HTMLElement|undefined;
        multiple: boolean;
    };

    constructor(pOptions: {
        open: () => void;
        close: () => void;
        isOpen: () => boolean;
        isValidIndex: (pIndex: number) => boolean;
        updateValue: (pIndex: number) => void;
        getScrollContainer: () => HTMLElement|undefined;
        multiple: boolean;
    }) {
        this._apiObject = pOptions;
    }

    clearState() {
        this.currentIndex = null;
        this.lastKeyIsNavigation = false;
    }

    addHandler(inputEl: HTMLElement, currentIndex?: number) {
        this._input = inputEl;
        this._boundKeyDown = this._handleKeyDown.bind(this);
        this.clearState();
        if (currentIndex != null) {
            this.currentIndex = currentIndex;
        }
        this._input.addEventListener('keydown', this._boundKeyDown);
    }

    removeHandler() {
        this._input.removeEventListener('keydown', this._boundKeyDown);
    }

    private _handleKeyDown(e: KeyboardEvent) {
        switch (e.key) {
            case 'Escape':
                this._apiObject.close();
                break;
            case 'ArrowDown':
                this.lastKeyIsNavigation = true;
                this._moveDown();
                e.preventDefault();
                break;
            case 'ArrowUp':
                this.lastKeyIsNavigation = true;
                this._moveUp();
                e.preventDefault();
                break;
            case 'Enter':
                if (this._apiObject.multiple) {
                    this._selectCurrentRow();
                } else {
                    if (this._apiObject.isOpen()) {
                        this._apiObject.close();
                    } else {
                        this._apiObject.open();
                    }
                }
                break;
            default:
                this.lastKeyIsNavigation = false;
                break;
        }
    }

    private _moveDown() {
        const moved = this._changeIndex(false);
        if (moved) {
            this._scrollToCurrent();
            if (!this._apiObject.multiple) {
                this._selectCurrentRow();
            }
        }

    }

    private _moveUp() {
        const moved = this._changeIndex(true);
        if (moved) {
            this._scrollToCurrent();
            if (!this._apiObject.multiple) {
                this._selectCurrentRow();
            }
        }
    }

    private _changeIndex(decrement = false) {
        if (this.currentIndex == null) {
            if (this._rowExists(0)) {
                this.currentIndex = 0;
                return true;
            } else {
                return false;
            }
        } else {
            const newIndex = decrement ? this.currentIndex - 1 : this.currentIndex + 1;
            if (this._rowExists(newIndex)) {
                this.currentIndex = newIndex;
                return true;
            } else {
                return false;
            }
        }
    }

    private _rowExists(pIndex: number|null) {
        if (pIndex == null) { 
            return false;
        } else {
            return this._apiObject.isValidIndex(pIndex);
        }
    }

    private _selectCurrentRow() {
        if (!this.lastKeyIsNavigation || !this._rowExists(this.currentIndex)) { return; }
        this._apiObject.updateValue(this.currentIndex!);
    }

    private _scrollToCurrent() {
        const scrollContainer = this._apiObject.getScrollContainer();
        if (this.currentIndex == null || scrollContainer == null) { return; }
        const buffer = scrollContainer.clientHeight / 2;

        const newScroll = this.currentIndex * 34;
        const topScroll = scrollContainer.scrollTop;
        const bottomScroll = topScroll + scrollContainer.clientHeight;

        if (newScroll <= bottomScroll + buffer) {
            scrollContainer.scrollTop = newScroll - buffer;
        } else if (topScroll <= newScroll) {
            scrollContainer.scrollTop = newScroll + buffer;
        }
    }
}

export default defineComponent({
    inheritAttrs: false
});
</script>

<style scoped>
.o365-select-container {
    max-height: 450px;
    overflow-y: auto;
}

.o365-select-container .focused:not(.active) {
    background-color: rgba(var(--bs-primary-rgb), .25);
}
.o365-select-container .active:not(.focused) {
    background-color: rgba(var(--bs-primary-rgb), .75);
}
.o365-select-container .focused.active {
    background-color: rgba(var(--bs-primary-rgb), 1);
}


.o365-select-container input {
    cursor: pointer;
}

.o365-select{
    padding-right: 3.33rem!important;
}

.o365-select-clear {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
</style>